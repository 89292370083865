import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// require.context(path,deep,regExp) =>(路径，是否引入子文件，正则)
let langFileds = require.context('./config/',false,/\.js$/)
console.log('每一个文件的名字',langFileds.keys())

let regExp = /\.\/([^\.\/]+)\.([^\.]+)$/ //正则用于匹配./en.js中的en

let messages = {}

langFileds.keys().forEach(item => {
    let props = regExp.exec(item)[1]  //得到例如en
    messages[props] = langFileds(item).default //得到例如：en:{...}
});
console.log('messages', messages);


let locale = localStorage.getItem('lang') || 'en-US'
console.log('locale', locale)
export default new VueI18n({
    locale,//指定语言段
    messages//定义语言字段
})