// 印尼语
export default {
  loginPage:{
    mobileNumber:'Nombor mudah alih',
    password:'kata laluan',
    forgotPassword:'Terlupa kata laluan',
    goToRegister:'Pergi untuk mendaftar',
    rememberPassword:'ingat kata laluan',
    login:'log masuk',
    noAccountYet:'Belum ada akaun?',
    mobileNumberPlaceholder:'Sila masukkan nombor telefon bimbit anda',
    mobileNumberMessage:'Sila masukkan nombor telefon bimbit anda',
    passwordPlaceholder:'Sila masukkan kata laluan',
    passwordMessage:'Sila masukkan kata laluan',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  //   注册页
  registerPage:{
    register:'  daftar ',
    mobileNumber:'Nombor mudah alih',
    mobileNumberPlaceholder :'Sila masukkan nombor telefon',
    mobileNumberMessage:'Sila masukkan nombor telefon',
    loginPassword:'kata laluan log masuk',
    loginPasswordPlaceholder:'Sila masukkan kata laluan log masuk',
    loginPasswordMessage:'Sila masukkan kata laluan log masuk',
    invitationCode:'Kod Jemputan',
    invitationCodePlaceholder:'Sila masukkan kod jemputan',
    invitationCodeMessage:'Sila masukkan kod jemputan',
    fundingPassword:'Kata laluan pembiayaan',
    fundingPasswordPlaceholder:'Sila masukkan kata laluan dana',
    fundingPasswordMessage:'Sila masukkan kata laluan dana',
    iKnowAndAgree:'Saya tahu dan bersetuju',
    accountOpeningAgreement:'"Perjanjian Pembukaan Akaun"',
    theTreaties:'Perjanjian',
    signUpNow:'Daftar sekarang',
    pleaseAgree:'Sila bersetuju dengan perjanjian pendaftaran terlebih dahulu',
    existingAccount:'akaun sedia ada',
    loginNow:'"Log masuk sekarang"',
    registrationFailed:'Pendaftaran gagal',
    registrationSuccessful:'Pendaftaran berjaya',
    iKnow:'Saya tahu',
    accountOpen:'Perjanjian Pembukaan Akaun'
  },
  // 公司资金
  culturePage:{
    title:' Kelayakan Syarikat'
},
// 主页页面
indexPage:{
  indexList:'获取首页数据失败',
  amazonLetMiaogouOpenYourWayToWealth:'AppLovin Biarkan Miaogou membuka jalan anda kepada kekayaan',
  myAssets:'Aset Saya($)',
  recharge:'cas semula',
  withdrawal:'Pengeluaran',
  yesterdaysIncome:'Pendapatan semalam($)',
  cumulativeIncome:'pendapatan terkumpul($)',
  todaysIncome:'Pendapatan hari ini($)',
  freezeFunds:'Bekukan dana($)',
  regularFinancialManagement:'pengurusan kewangan biasa',
  quickRecharge:'Caj semula cepat',
  quickWithdrawal:'Pengeluaran Cepat',
  inviteFriends:'Jemput kawan',
  taskHall:'Dewan Tugas',
  unlocked:'dikunci',
  service:'perkhidmatan pelanggan',
  leaderboard:'Pengunduran'
},
// list页面
listPage:{
  secondPurchaseRecord:'Rekod pembelian kedua',
  this:'Data ini disediakan oleh pegawai utama koperasi',
  Remaining:'Baki aset yang tersedia($)',
  allOrders:'Semua pesanan',
  toBeProcessed:'untuk diproses',
  completed:'Selesai',
  freezing:'Membeku',
  totalAmountOfTheOrder:'Jumlah jumlah pesanan',
  commission:'Komisen',
  Estimated:'Anggaran rebat komisen',
  submitOrder:'Serahkan pesanan',
  secondpurchasetime:'Masa pembelian kedua:',
  secondPurchaseNumber:'Nombor pembelian kedua:',
  NotSubmitted:'Belum diserahkan lagi',
  submitNow:'Serahkan sekarang'
},
// order页面
orderPage:{
  grabAnOrder:'Dapatkan pesanan',
  hiShop:'Kedai Hai',
  winTheFutureWithYou:'',
  orderText:'Diasaskan pada 1994, AppLovin Marketplace bekerjasama dengan lebih 1 juta syarikat e-dagang, memproses 150,000 hingga 30 juta pesanan setiap hari. Memandangkan pengurusan dana diamanahkan kepada syarikat pengurusan aset, ia boleh mengelakkan permintaan bayaran balik pengguna dan penerbangan modal syarikat e-dagang, dan klien awan pintar dan IP pengguna digunakan mengikut peraturan yang ditetapkan. Pertandingan pesanan automatik akan menghalang peniaga daripada diharamkan, diturunkan taraf atau diharamkan kerana kesilapan yang dilakukan oleh pengguna yang mengambil pesanan. Sebagai perantara, kami akan terus bekerja keras untuk meningkatkan kepercayaan dan kecekapan peniaga dan pengguna apabila bersaing untuk pesanan!',
  bronzeMember:'Ahli Gangsa',
  commission:'Komisen',
  Matching:'Pesanan yang sepadan untuk anda, sila tunggu sebentar',
  dispatch:'Penghantaran',
  grabAnOrder:'Dapatkan pesanan',
  balance:'Baki',
  recharge:'cas semula',
  withdrawal:'Pengeluaran',
  matchingRules:'Peraturan padanan',
  commissionEarnedToday:'Komisen diperoleh hari ini',
  Today:'Hari ini telah mendapat bilangan pesanan',
  oddNumber:'Baki nombor ganjil hari ini',
  yesterdays:'Komisen semalam',
  User:'Pengguna',
  getCommission:'Dapatkan komisen'
},
// my页面
myPage:{
  logout:'log keluar',
  areYouSureYouWantToLogOut:'Adakah anda pasti mahu log keluar?',
  confirnText:'OK',
  cancleText:'Batal',
  amazonReputation:'AppLovin reputasi:',
  accountBalance:'Baki Akaun',
  recharge:'cas semula',
  withdrawal:'Pengeluaran',
  deliveryAddress:'alamat penghantaran',
  iWantToPromote:'Saya mahu mempromosikan',
  secondPurchaseRecord:'Rekod pembelian kedua',
  accountDetails:'Butiran Akaun',
  teamReport:'Laporan Pasukan',
  companyQualification:'Kelayakan Syarikat',
	platformRules: 'Aturan Platform'
},
addressPage:{
  address:' Alamat ',
  realName:'nama sebenar',
  pleaseEnterYourRealName:'Sila masukkan nama sebenar anda',
  mobileNumber:'Nombor mudah alih',
  pleaseEnterThePhoneNumber:'Sila masukkan nombor telefon',
  region:'wilayah',
  pleaseEnterTheRegion:'Sila masukkan kawasan',
  detailedAddress:'Alamat terperinci',
  pleaseEnterDetailedAddress:'Sila masukkan alamat terperinci',
  submit:'hantar'
},
// Popularize页面
popularizePage:{
  iWantToPromote:' Saya mahu mempromosikan ',
  inviteNewUsers:'Jemput pengguna baharu',
  winTheFutureWithYou:'Menangi masa depan bersama anda',
  screenshot:'Tangkapan skrin simpan kod QR',
  friend:'Pengenalan dan pendaftaran rakan',
  complete:'Lengkapkan pendaftaran dan ambil pesanan',
  distribute:'Edarkan sampul merah tunai dalam masa 24 jam',
  invitationCode:'Kod Jemputan: '
},
accountDetailsPage:{
  accountDetails:' Butiran Akaun ',
  startDate:'tarikh mula',
  endDate:'tarikh tamat',
  search:'Cari',
  alltypes:'semua jenis',
  withdrawalRecord:'Rekod pengeluaran',
  rechargeRecord:'rekod cas semula',
  withDrawal:'Pengeluaran',
  UAddress:'Alamat U',
  rebateForOrderGrabbing:'Rebat untuk mengambil pesanan',
  orderGrab:'Tempahan Grab',
  recharge:'cas semula',
  tryToLoad:'Berjuang untuk memuatkan',
  noMore:'Cukup',
  cancel:'Batal',
  ok:'OK',
  pleaseSelectATime:'Sila pilih masa'
},
teamPage:{
  teamReport:' Laporan Pasukan ',
  startDate:'tarikh mula',
  endDate:'tarikh tamat',
  search:'Cari',
  teamBalance:'Imbangan Pasukan($)',
  teamFlow:'aliran pasukan($)',
  totalTeamRecharge:'Jumlah cas semula pasukan($)',
  theTotalWithdrawalOfTheTeam:'Jumlah penarikan diri pasukan($)',
  teamOrderCommission:'komisen pesanan pasukan($)',
  myCommission:'Komisen saya($)',
  directPushNumber:'Nombor tolak terus',
  teamSize:'saiz pasukan',
  todaysNewNumberOfPeople: 'Jumlah orang baharu hari ini',
  todaysActiveNumber:'Nombor aktif hari ini',
  level1:'Tahap 1',
  level2:'Menengah',
  level3:'tahap tiga',
  tryToLoad:'Berjuang untuk memuatkan',
  noMore:'Cukup',
  mobilePhone:'Nombor telefon bimbit',
  Number:'Bilangan promoter',
  withdrawals:'Pengeluaran',
  recharge:'cas semula',
  time:'Masa pendaftaran'
},
// 充值页面
rechargePage:{
  recharge:' cas semula ',
  currentBalance:'Baki Semasa ($)',
  rechargeApplication:'aplikasi cas semula($)',
  accumulatedRecharged:'Terkumpul dicas semula($)',
  rechargeAmount:'jumlah cas semula',
  pleaseEnterTheRechargeAmount:'Sila masukkan jumlah caj semula',
  rechargeNow:'Cas semula sekarang'
},
// 提现页面
withDrawalPage:{
  withDrawal:' Pengeluaran ',
  currentBalance:'Baki Semasa ($)',
  withdrawalApplicationInProgress:'Permohonan pengeluaran sedang dijalankan($)',
  cumulativelyWithdrawn:'Ditarik balik secara kumulatif($)',
  withdrawalAmount:'jumlah pengeluaran',
  pleaseEnterTheWithdrawalAmount:'Sila masukkan jumlah pengeluaran',
  withdrawAll:'jumlah pengeluaran',
  uAddress:'Alamat U',
  pleaseEnterUAddress:'Sila masukkan alamat U',
  transactionPassword:'kata laluan transaksi',
  pleaseEnterTheTransactionPassword:'Sila masukkan kata laluan transaksi',
  withdrawNow:'Tarik diri sekarang',
  allFieldsCannotBeEmpty:'Semua medan tidak boleh kosong',
  able:'Jumlah wang tunai yang boleh dikeluarkan',
  networkType:'Jenis rangkaian',
	minPrice: 'Jumlah penarikan minimum $100',
	minXinyufen: 'Jika Anda memiliki skor kredit yang tidak mencukupi dan tidak dapat menarik uang, silakan hubungi layanan pelanggan!'
},
	vipPage:{
		'agent_mode': 'Mode Agen',
		'current_level': 'Level Saat Ini',
		'vip1_text1': 'Terima satu set 40 tugas data aplikasi',
		'vip1_text2': 'Keuntungan 0,50% per aplikasi',
		'vip1_text3': 'Aktifkan dengan 100.00 USDT',
		
		'vip2_text1': 'Terima satu set 50 tugas data aplikasi',
		'vip2_text2': 'Keuntungan 0,60% per aplikasi',
		'vip2_text3': 'Aktifkan dengan 2,000.00 USDT',
		
		'vip3_text1': 'Terima satu set 55 tugas data aplikasi',
		'vip3_text2': 'Keuntungan 0,80% per aplikasi',
		'vip3_text3': 'Aktifkan dengan 5,000.00 USDT',
		
		'vip4_text1': 'Terima satu set 60 tugas data aplikasi',
		'vip4_text2': 'Keuntungan 1,00% per aplikasi',
		'vip4_text3': 'Aktifkan dengan 10,000.00 USDT',
	}
}