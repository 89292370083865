// 泰语
export default {
  loginPage:{
    mobileNumber:'หมายเลขโทรศัพท์มือถือ',
    password:'รหัสผ่าน',
    forgotPassword:'ลืมรหัสผ่าน',
    goToRegister:'ไปลงทะเบียน',
    rememberPassword:'จำรหัสผ่าน',
    login:'เข้าสู่ระบบ',
    noAccountYet:'ยังไม่มีบัญชี?',
    mobileNumberPlaceholder:'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
    mobileNumberMessage:'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
    passwordPlaceholder:'กรุณาใส่รหัสผ่าน',
    passwordMessage:'กรุณาใส่รหัสผ่าน',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  //   注册页
  registerPage:{
    register:'  ลงทะเบียน ',
    mobileNumber:'หมายเลขโทรศัพท์มือถือ',
    mobileNumberPlaceholder :'กรุณาใส่หมายเลขโทรศัพท์',
    mobileNumberMessage:'กรุณาใส่หมายเลขโทรศัพท์',
    loginPassword:'รหัสผ่านสำหรับเข้าสู่ระบบ',
    loginPasswordPlaceholder:'กรุณาป้อนรหัสผ่านเข้าสู่ระบบ',
    loginPasswordMessage:'กรุณาป้อนรหัสผ่านเข้าสู่ระบบ',
    invitationCode:'รหัสเชิญ',
    invitationCodePlaceholder:'กรุณาใส่รหัสเชิญ',
    invitationCodeMessage:'กรุณาใส่รหัสเชิญ',
    fundingPassword:'รหัสผ่านการระดมทุน',
    fundingPasswordPlaceholder:'กรุณาใส่รหัสผ่านกองทุน',
    fundingPasswordMessage:'กรุณาใส่รหัสผ่านกองทุน',
    iKnowAndAgree:'ฉันรู้และเห็นด้วย',
    accountOpeningAgreement:'"ข้อตกลงการเปิดบัญชี"',
    theTreaties:'สนธิสัญญา',
    signUpNow:'สมัครเลย',
    pleaseAgree:'โปรดยอมรับข้อตกลงการลงทะเบียนก่อน',
    existingAccount:'บัญชีที่มีอยู่',
    loginNow:'"เข้าสู่ระบบตอนนี้"',
    registrationFailed:'ลงทะเบียนไม่สําเร็จ',
    registrationSuccessful:'ลงทะเบียนสําเร็จ',
    iKnow:'ฉันรู้',
    accountOpen:'ข้อตกลงการเปิดบัญชี'
  },
  // 主页页面
  indexPage:{
    indexList:'ไม่สามารถรับข้อมูลโฮมเพจได้',
    amazonLetMiaogouOpenYourWayToWealth:'AppLovinให้เหมียวโชเปิดเส้นทางสู่ความมั่งคั่ง',
    myAssets:'สินทรัพย์ของฉัน($)',
    recharge:'ชาร์จ',
    withdrawal:'ถอน',
    yesterdaysIncome:'รายได้ของเมื่อวาน($)',
    cumulativeIncome:'รายได้สะสม($)',
    todaysIncome:'รายได้วันนี้($)',
    freezeFunds:'การอายัดเงินทุน($)',
    regularFinancialManagement:'ธนาคารทั่วไป',
    quickRecharge:'เติมเงินอย่างรวดเร็ว',
    quickWithdrawal:'การจ่ายเงินที่รวดเร็ว',
    inviteFriends:'เชิญเพื่อน',
    taskHall:'ล็อบบี้ภารกิจ',
    unlocked:'ปลด ล็อค',
	service:'ฝ่ายบริการลูกค้า',
	leaderboard:'ถอนเงินสด'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'บันทึกการซื้อครั้งที่สอง',
    this:'ข้อมูลนี้จัดทําโดยเจ้าหน้าที่ความร่วมมือที่สําคัญ',
    Remaining:'สินทรัพย์ที่มีอยู่ที่เหลือ($)',
    allOrders:'ซิงเกิลทั้งหมด',
    toBeProcessed:'รอ',
    completed:'เสร็จ สมบูรณ์',
    freezing:'แช่ แข็ง',
    totalAmountOfTheOrder:'ยอดรวมของคําสั่งซื้อ',
    commission:'คณะกรรมการ',
    Estimated:'เงินคืนโดยประมาณ',
    submitOrder:'ส่งคําสั่งซื้อ',
    secondpurchasetime:'วินาทีในการซื้อเวลา:',
    secondPurchaseNumber:'หมายเลขการซื้อวินาที:',
    NotSubmitted:'อย่าส่งในเวลานี้',
    submitNow:'ส่งตอนนี้'
  },
  // order页面
  orderPage:{
    grabAnOrder:'แกร็บออเดอร์ไม่หยุด',
    hiShop:'สวัสดีร้านค้า',
    winTheFutureWithYou:'อนาคตที่ชนะกับคุณ',
    orderText:'AppLovin Marketplace ก่อตั้งขึ้นในปี 1994&nbsp;ทํางานร่วมกับ บริษัท อีคอมเมิร์ซมากกว่า 1 ล้านแห่งและประมวลผลระหว่าง 150,000 ถึง 30 ล้านคําสั่งซื้อต่อวัน เนื่องจากการจัดการกองทุนได้รับความไว้วางใจจาก บริษัท จัดการสินทรัพย์จึงสามารถหลีกเลี่ยงคําขอคืนเงินของผู้ใช้และเที่ยวบินเงินทุนจาก บริษัท อีคอมเมิร์ซและลูกค้าคลาวด์อัจฉริยะและ IP ของผู้ใช้จะถูกใช้ตามกฎที่ตั้งไว้ การแข่งขันคําสั่งซื้ออัตโนมัติจะป้องกันไม่ให้ผู้ค้าถูกแบนลดระดับหรือถูกแบนเนื่องจากความผิดพลาดของผู้ใช้ที่คว้าคําสั่งซื้อ ในฐานะตัวกลางเราจะพยายามต่อไปเพื่อเพิ่มความไว้วางใจและประสิทธิภาพของผู้ค้าและผู้ใช้เมื่อแข่งขันเพื่อสั่งซื้อ!',
    bronzeMember:'สมาชิกระดับบรอนซ์',
    commission:'คอมมิชชั่น',
    Matching:'จับคู่คําสั่งซื้อสําหรับคุณโปรดรอสักครู่',
    dispatch:'จัด ส่ง',
    grabAnOrder:'คว้าคําสั่งซื้อ',
    balance:'ตาชั่ง',
    recharge:'ชาร์จ',
    withdrawal:'ถอน',
    matchingRules:'ตรงกับกฎ',
    commissionEarnedToday:'คอมมิชชั่นที่ได้รับวันนี้',
    Today:'จํานวนอัตราต่อรองได้รับการคว้าในวันนี้',
    oddNumber:'จํานวนเอกพจน์ที่เหลืออยู่ในปัจจุบัน',
    yesterdays:'คอมมิชชั่นเมื่อวานนี้',
    User:'  ผู้ใช้',
    getCommission:'รับหน้าที่'
  },
  // my页面
  myPage:{
    logout:'ลงชื่อออก',
    areYouSureYouWantToLogOut:'คุณแน่ใจหรือไม่ว่าคุณออกจากระบบ',
    confirnText:'เธอแน่ใจหรือ',
    cancleText:'ยกเลิก',
    amazonReputation:'AppLovin ความน่าเชื่อถือ:',
    accountBalance:'ยอดเงินในบัญชี',
    recharge:'ชาร์จ',
    withdrawal:'ถอน',
    deliveryAddress:'ที่อยู่จัดส่ง',
    iWantToPromote:'ฉันต้องการโปรโมต',
    secondPurchaseRecord:'บันทึกการซื้อครั้งที่สอง',
    accountDetails:'รายละเอียดบัญชี',
    teamReport:'รายงานของทีม',
    companyQualification:'คุณสมบัติของบริษัท',
		platformRules: 'กฎของแพลตฟอร์ม'
  },
  accountDetailsPage:{
    accountDetails:'รายละเอียดบัญชี',
    startDate:'วันที่เริ่มต้น',
    endDate:'วันที่สิ้นสุด',
    search:'ค้น',
    alltypes:'ทุกประเภท',
    withdrawalRecord:'ประวัติการถอนเงิน',
    rechargeRecord:'บันทึกการเติมเงิน',
    withDrawal:'ถอน',
    UAddress:'ที่อยู่ U',
    rebateForOrderGrabbing:'เงินคืนสําหรับการสั่งซื้อคว้า',
    orderGrab:'คว้าคําสั่งซื้อ',
    recharge:'ชาร์จ',
    tryToLoad:'ดิ้นรนเพื่อโหลด',
    noMore:'ไม่อีกแล้ว',
    cancel:'ยกเลิก',
    ok:'เธอแน่ใจหรือ',
    pleaseSelectATime:'โปรดเลือกเวลา'
},
teamPage:{
  teamReport:'รายงานของทีม',
  startDate:'วันที่เริ่มต้น',
  endDate:'วันที่สิ้นสุด',
  search:'ค้น',
  teamBalance:'ความสมดุลของทีม($)',
  teamFlow:'โฟลว์ทีม($)',
  totalTeamRecharge:' การเติมเงินรวมทีม($)',
  theTotalWithdrawalOfTheTeam:'การถอนตัวของทีมทั้งหมด($)',
  teamOrderCommission:'ค่าคอมมิชชั่นสําหรับการสั่งซื้อของทีม($)',
  myCommission:'คอมมิชชั่นของฉัน($)',
  directPushNumber:'หัวอ้างอิงโดยตรง',
  teamSize:'จํานวนสมาชิกในทีม',
  todaysNewNumberOfPeople: 'หมายเลขใหม่ของวันนี้',
  todaysActiveNumber:'จํานวนคนที่กระตือรือร้นในวันนี้',
  level1:'ระดับ',
  level2:'ระดับ 2',
  level3:'ระดับสาม',
  tryToLoad:'ดิ้นรนเพื่อโหลด',
  noMore:'ไม่อีกแล้ว',
  mobilePhone:'หมายเลขโทรศัพท์มือถือ',
  Number:'จํานวนโปรโมเตอร์',
  withdrawals:'ถอน',
  recharge:'ชาร์จ',
  time:'เวลาลงทะเบียน'
},
culturePage:{
  title:'คุณสมบัติของบริษัท'
},
addressPage:{
  address:'ที่อยู่',
  realName:'ชื่อจริง',
  pleaseEnterYourRealName:'กรุณาใส่ชื่อจริงของคุณ',
  mobileNumber:'หมายเลขโทรศัพท์มือถือ',
  pleaseEnterThePhoneNumber:'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  region:'บริเวณ',
  pleaseEnterTheRegion:'โปรดป้อนภูมิภาค',
  detailedAddress:'ที่อยู่แบบเต็ม',
  pleaseEnterDetailedAddress:'กรุณากรอกที่อยู่แบบเต็มของคุณ',
  submit:'ส่ง'
},
// Popularize页面
popularizePage:{
  iWantToPromote:'ฉันต้องการโปรโมต',
  inviteNewUsers:'เชิญผู้ใช้ใหม่',
  winTheFutureWithYou:'อนาคตที่ชนะกับคุณ',
  screenshot:'ถ่ายภาพหน้าจอเพื่อบันทึกรหัส QR',
  friend:'การระบุตัวตนและการลงทะเบียนเพื่อน',
  complete:'ดําเนินการลงทะเบียนให้เสร็จสิ้น',
  distribute:'ซองเงินสดสีแดงจะถูกแจกจ่ายภายใน 24 ชั่วโมง',
  invitationCode:'รหัสเชิญ:'
},
// 提现页面
withDrawalPage:{
  withDrawal:'ถอน',
  currentBalance:'จํานวนเงินปัจจุบัน($)',
  withdrawalApplicationInProgress:'คําขอถอนเงิน($)',
  cumulativelyWithdrawn:'การถอนเงินสะสม($)',
  withdrawalAmount:'จํานวนเงินที่ถอน',
  pleaseEnterTheWithdrawalAmount:'กรุณากรอกจํานวนเงินที่ถอน',
  withdrawAll:'ถอนออกทั้งหมด',
  uAddress:'ที่อยู่ U',
  pleaseEnterUAddress:'กรุณากรอกที่อยู่ U',
  transactionPassword:'รหัสผ่านการซื้อขาย',
  pleaseEnterTheTransactionPassword:'กรุณาใส่รหัสผ่านการทําธุรกรรม',
  withdrawNow:'ถอนทันที',
  allFieldsCannotBeEmpty:'เขตข้อมูลทั้งหมดต้องไม่ว่างเปล่า',
  able:'จํานวนเงินสดที่สามารถถอนได้',
  networkType:'ประเภทเครือข่าย',
	minPrice: 'จำนวนถอนขั้นต่ำ $100',
	minXinyufen: 'หากคุณมีคะแนนเครดิตไม่เพียงพอและไม่สามารถถอนเงินได้โปรดติดต่อฝ่ายบริการลูกค้า!'
},
// 充值页面
rechargePage:{
  recharge:'ชาร์จ',
  currentBalance:'ยอดดุลปัจจุบัน($)',
  rechargeApplication:'กําลังดําเนินการเติมเงิน($)',
  accumulatedRecharged:'เติมเงินสะสม($)',
  rechargeAmount:'จํานวนเงินที่เติม',
  pleaseEnterTheRechargeAmount:'กรุณากรอกจํานวนเงินที่ต้องการเติมเงิน',
  rechargeNow:'เติมเงินตอนนี้'
},
// 抢单列表页面
grabListPage:{
  failedToGetTheOrderGrabList:'ไม่ได้รับรายการคว้าคําสั่งซื้อ'
},
	vipPage:{
		'agent_mode': 'โหมดตัวแทน',
		'current_level': 'ระดับปัจจุบัน',
		'vip1_text1': 'รับชุดข้อมูลงานแอปจำนวน 40 รายการ',
		'vip1_text2': 'กำไร 0.50% ต่อการสมัคร',
		'vip1_text3': 'เปิดใช้งานด้วย 100.00 USDT',
		
		'vip2_text1': 'รับชุดข้อมูลงานแอปจำนวน 50 รายการ',
		'vip2_text2': 'กำไร 0.60% ต่อการสมัคร',
		'vip2_text3': 'เปิดใช้งานด้วย 2,000.00 USDT',
		
		'vip3_text1': 'รับชุดข้อมูลงานแอปจำนวน 55 รายการ',
		'vip3_text2': 'กำไร 0.80% ต่อการสมัคร',
		'vip3_text3': 'เปิดใช้งานด้วย 5,000.00 USDT',
		
		'vip4_text1': 'รับชุดข้อมูลงานแอปจำนวน 60 รายการ',
		'vip4_text2': 'กำไร 1.00% ต่อการสมัคร',
		'vip4_text3': 'เปิดใช้งานด้วย 10,000.00 USDT',
	}
}