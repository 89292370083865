//德语
export default {
    accountDetailsPage:{
        accountDetails:'Kontodaten',
        startDate:'Startdatum',
        endDate:'Enddatum',
        search:'suchen',
        alltypes:'alle Arten',
        withdrawalRecord:'Auszahlungsunterlagen',
        rechargeRecord:'Aufladeaufzeichnung',
        withDrawal:'Bargeld abheben',
        UAddress:'U-Adresse',
        rebateForOrderGrabbing:'Rabatt bestellen',
        orderGrab:'Holen Sie sich eine Bestellung',
        recharge:'nachfüllen',
        tryToLoad:'Es wird hart geladen',
        noMore:'nicht mehr',
        cancel:'Stornieren',
        ok:'Sicher',
        pleaseSelectATime:'Bitte wählen Sie eine Uhrzeit aus'
    },
    teamPage:{
        teamReport:'Teamberichte',
        startDate:'Startdatum',
        endDate:'Enddatum',
        search:'suchen',
        teamBalance:'Teambalance($)',
        teamFlow:'Teamfluss($)',
        totalTeamRecharge:'Vollständige Aufladung des Teams($)',
        theTotalWithdrawalOfTheTeam:'Totaler Rückzug des Teams($)',
        teamOrderCommission:'Teamauftragsprovision($)',
        myCommission:'meine Provision($)',
        directPushNumber:'Anzahl der direkten Empfehlungen',
        teamSize:'Teamgröße',
        todaysNewNumberOfPeople: 'Heute wurden neue Leute hinzugefügt',
        todaysActiveNumber:'Anzahl der aktiven Menschen heute',
        level1:'Stufe 1',
        level2:'Stufe 2',
        level3:'Stufe drei',
        tryToLoad:'Es wird hart geladen',
        noMore:'nicht mehr',
        mobilePhone:'Telefonnummer',
        Number:'Aktionsnummer',
        withdrawals:'Bargeld abheben',
        recharge:'nachfüllen',
        time:'Anmeldezeit'
    },
    culturePage:{
        title:'Unternehmensqualifikationen',
        text:'AppLovin verfolgt das Konzept der „Kundenorientierung“ und den Glauben an „täglich niedrige Preise, authentische Produkte“ und verkauft zig Millionen Produkte wie Bücher, Computer, digitale Haushaltsgeräte, Kaufhäuser für Mütter und Kleinkinder, Kleidung und Taschen , usw. AppLovin China bietet professionelle Dienstleistungen: täglich echte Lizenzware zu günstigen Preisen und landesweit garantiert maschinengedruckte Rechnungen. Nachnahme, Rückgabe und Umtausch innerhalb von 30 Tagen. AppLovin® bietet Verbrauchern weltweit ein bequemes und schnelles Online-Einkaufserlebnis'
    },
    // 登录页
    loginPage:{
        mobileNumber:'Telefonnummer',
        mobileNumberPlaceholder:'Bitte geben Sie die Mobiltelefonnummer ein',
        mobileNumberMessage:'Bitte geben Sie eine standardisierte Mobiltelefonnummer ein',
        password:'Passwort',
        passwordPlaceholder:'Bitte geben Sie das Passwort ein',
        passwordMessage:'Das eingegebene Passwort ist falsch. Bitte versuchen Sie es erneut.',
        forgotPassword:'vergessen Sie das Passwort',
        goToRegister:'Gehen Sie zur Registrierung',
        rememberPassword:'Passwort merken',
        login:'Einloggen',
        noAccountYet:'Noch kein Konto?',
        loginSuccessful:'Anmeldung erfolgreich！',
        loginFailed:'Fehler bei der Anmeldung！'
      },
    //   注册页
      registerPage:{
        register:'registrieren',
        mobileNumber:'Telefonnummer',
        mobileNumberPlaceholder :'Bitte geben Sie die Mobiltelefonnummer ein',
        mobileNumberMessage:'Bitte geben Sie eine standardisierte Mobiltelefonnummer ein',
        loginPassword:'Login-Passwort',
        loginPasswordPlaceholder:'Bitte geben Sie Ihr Login-Passwort ein',
        loginPasswordMessage:'Das von Ihnen festgelegte Anmeldekennwort darf nicht leer sein',
        invitationCode:'Einladungscode',
        invitationCodePlaceholder:'Bitte geben Sie den Einladungscode ein',
        invitationCodeMessage:'Der von Ihnen eingegebene Einladungscode ist falsch',
        fundingPassword:'Passwort für den Fonds',
        fundingPasswordPlaceholder:'Bitte geben Sie das Fondspasswort ein',
        fundingPasswordMessage:'Das von Ihnen festgelegte Fondspasswort darf nicht leer sein',
        iKnowAndAgree:'Ich habe es verstanden und bin einverstanden',
        accountOpeningAgreement:'"Kontoeröffnungsvertrag"',
        theTreaties:'Verträge',
        signUpNow:'Registrieren Sie sich jetzt',
        pleaseAgree:'Bitte stimmen Sie zunächst der Registrierungsvereinbarung zu',
        existingAccount:'Habe bereits ein Konto',
        loginNow:'"Melden Sie sich jetzt an"',
        registrationFailed:'Die Registrierung ist fehlgeschlagen',
        registrationSuccessful:'Registrierung erfolgreich',
        iKnow:'Ich weiß',
        accountOpen:'Kontoeröffnungsvertrag'
      },
      // my页面
      myPage:{
        logout:'Abmelden',
        areYouSureYouWantToLogOut:'Möchten Sie sich wirklich abmelden?？',
        confirnText:'Sicher',
        cancleText:'Stornieren',
        amazonReputation:'Reputationspunkte:',
        accountBalance:'Kontostand',
        recharge:'nachfüllen',
        withdrawal:'Bargeld abheben',
        deliveryAddress:'Lieferadresse',
        iWantToPromote:'Ich möchte fördern',
        secondPurchaseRecord:'Zweiter Kaufrekord',
        accountDetails:'Kontodaten',
        teamReport:'Teamberichte',
        companyQualification:'Unternehmensqualifikationen',
		platformRules: 'Plattform-Regeln'
      },
      // 充值页面
      rechargePage:{
        recharge:'nachfüllen',
        currentBalance:'Aktueller Kontostand($)',
        rechargeApplication:'Aufladeanwendung läuft($)',
        accumulatedRecharged:'Total aufgeladen($)',
        rechargeAmount:'Aufladebetrag',
        pleaseEnterTheRechargeAmount:'Bitte geben Sie den Aufladebetrag ein',
        rechargeNow:'Jetzt aufladen'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'Bestellliste konnte nicht abgerufen werden'
      },
      // 提现页面
      withDrawalPage:{
       withDrawal:'Bargeld abheben',
       currentBalance:'Aktueller Betrag($)',
       withdrawalApplicationInProgress:'Auszahlungsantrag in Bearbeitung($)',
       cumulativelyWithdrawn:'Kumulierte Bargeldabhebungen($)',
       withdrawalAmount:'Betrag abheben',
       pleaseEnterTheWithdrawalAmount:'Bitte geben Sie den Auszahlungsbetrag ein',
       withdrawAll:'Alle zurückziehen',
       uAddress:'U-Adresse',
       pleaseEnterUAddress:'Bitte geben Sie die U-Adresse ein',
       transactionPassword:'Transaktionspasswort',
       pleaseEnterTheTransactionPassword:'Bitte geben Sie das Transaktionspasswort ein',
       withdrawNow:'Sofort zurückziehen',
       allFieldsCannotBeEmpty:'Es dürfen nicht alle Felder leer sein',
       able:'Verfügbarer Bargeldbetrag',
	   networkType:'Netzwerktyp',
	   minPrice: 'Mindestauszahlungsbetrag 100 $',
	   minXinyufen: 'Wenn Sie eine unzureichende Kreditwürdigkeit haben und kein Geld abheben können, wenden Sie sich bitte an den Kundendienst!'
   },
      // 主页页面
    indexPage:{
      indexList:'Homepage-Daten konnten nicht abgerufen werden',
      amazonLetMiaogouOpenYourWayToWealth:'Mit AppLovin öffnet miagou Ihnen den Weg zum Wohlstand',
      myAssets:'mein Vermögen($)',
      recharge:'nachfüllen',
      withdrawal:'Bargeld abheben',
      yesterdaysIncome:'Der Verdienst von gestern($)',
      cumulativeIncome:'Kumuliertes Einkommen($)',
      todaysIncome:'Der heutige Verdienst($)',
      freezeFunds:'Gelder einfrieren($)',
      regularFinancialManagement:'Regelmäßige Finanzverwaltung',
      quickRecharge:'Schnelles Aufladen',
      quickWithdrawal:'Schnelle Bargeldabhebung',
      inviteFriends:'Freunde einladen',
      taskHall:'Missionshalle',
      unlocked:'Entsperrt',
	  service:'Kundendienst',
	  leaderboard:'Auszahlungsunterlagen'
    },
    addressPage:{
      address:'Adresse',
      realName:'richtiger Name',
      pleaseEnterYourRealName:'Bitte geben Sie Ihren richtigen Namen ein',
      mobileNumber:'Telefonnummer',
      pleaseEnterThePhoneNumber:'Bitte geben Sie die Mobiltelefonnummer ein',
      region:'Bereich',
      pleaseEnterTheRegion:'Bitte geben Sie die Region ein',
      detailedAddress:'Detaillierte Adresse',
      pleaseEnterDetailedAddress:'Bitte geben Sie eine detaillierte Adresse ein',
      submit:'einreichen'
    },
    // order页面
    orderPage:{
      grabAnOrder:'Ununterbrochen Bestellungen entgegennehmen',
      hiShop:'HiGo',
      winTheFutureWithYou:'Gewinnen Sie mit Ihnen die Zukunft',
      orderText:'AppLovin wurde 1994 gegründet, arbeitet mit mehr als 1 Million E-Commerce-Unternehmen zusammen und verarbeitet 150.000 bis 30 Millionen Bestellungen pro Tag. Da die Fondsverwaltung einer Vermögensverwaltungsgesellschaft anvertraut wird, können Rückerstattungsanträge der Benutzer und die Abwanderung von E-Commerce-Unternehmen vermieden werden. Intelligente Cloud-Clients und Benutzer-IPs werden nach festgelegten Regeln verwendet. Durch den automatischen Bestellwettbewerb wird verhindert, dass Händler aufgrund von Fehlern von Benutzern, die Bestellungen entgegennehmen, gesperrt, herabgestuft oder gesperrt werden. Als Vermittler werden wir weiterhin hart daran arbeiten, das Vertrauen und die Effizienz von Händlern und Nutzern im Wettbewerb um Bestellungen zu verbessern!',
      bronzeMember:'Bronze-Mitglied',
      commission:'Kommission',
      Matching:'Die Bestellung wird für Sie abgeglichen.',
      dispatch:'Versandauftrag',
      grabAnOrder:'Holen Sie sich eine Bestellung',
      balance:'Gleichgewicht',
      recharge:'nachfüllen',
      withdrawal:'Bargeld abheben',
      matchingRules:'Matching-Regeln',
      commissionEarnedToday:'Heute verdiente Provision',
      Today:'Anzahl der heute eingegangenen Bestellungen',
      oddNumber:'Die restlichen ungeraden Zahlen des Tages',
      yesterdays:'Die Kommission von gestern',
      User:'Benutzer',
      getCommission:'Provision bekommen'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:'Ich möchte fördern',
      inviteNewUsers:'Laden Sie neue Benutzer ein',
      winTheFutureWithYou:'Gewinnen Sie mit Ihnen die Zukunft',
      screenshot:'Machen Sie einen Screenshot und speichern Sie den QR-Code',
      friend:'Identifizierung und Registrierung von Freunden',
      complete:'Schließen Sie die Registrierung ab und holen Sie sich Bestellungen',
      distribute:'Bargeldrote Umschläge werden innerhalb von 24 Stunden verteilt',
      invitationCode:'Einladungscode:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'Zweiter Kaufrekord',
      this:'Diese Daten werden von wichtigen Genossenschaftsbeamten bereitgestellt',
      Remaining:'verbleibende verfügbare Vermögenswerte($)',
      allOrders:'Alle Bestellungen',
      toBeProcessed:'Ausstehend',
      completed:'Vollendet',
      freezing:'Einfrieren',
      totalAmountOfTheOrder:'Gesamtbestellsaldo',
      commission:'Kommission',
      Estimated:'Geschätzte Provisionsrückerstattung',
      submitOrder:'Bestellung abschicken',
      secondpurchasetime:'Zweiter Kaufzeitpunkt:',
      secondPurchaseNumber:'Zweite Kaufnummer:',
      NotSubmitted:'Noch nicht eingereicht',
      submitNow:'Jetzt einreichen'
    },
	vipPage:{
		'agent_mode': 'Proxy-Modus',
		'current_level': 'aktuelles Niveau',
		'vip1_text1': 'Erhalten Sie einen Satz von 40 Anwendungsdatenaufgaben',
		'vip1_text2': 'Verdienen Sie 0.50 % Gewinn pro Anwendung',
		'vip1_text3': 'Mit 100.00 USDT aktivieren',
		
		'vip2_text1': 'Erhalten Sie einen Satz von 50 Anwendungsdatenaufgaben',
		'vip2_text2': 'Verdienen Sie 0.60 % Gewinn pro Anwendung',
		'vip2_text3': 'Mit 2,000.00 USDT aktivieren',
		
		'vip3_text1': 'Erhalten Sie einen Satz von 55 Anwendungsdatenaufgaben',
		'vip3_text2': 'Verdienen Sie 0.80 % Gewinn pro Anwendung',
		'vip3_text3': 'Mit 5,000.00 USDT aktivieren',
		
		'vip4_text1': 'Erhalten Sie einen Satz von 60 Anwendungsdatenaufgaben',
		'vip4_text2': 'Verdienen Sie 1.00 % Gewinn pro Anwendung',
		'vip4_text3': 'Mit 10,000.00 USDT aktivieren',
	}
}