// 波兰
export default {
    accountDetailsPage:{
        accountDetails:'Szczegóły konta',
        startDate:'data rozpoczęcia',
        endDate:'data końcowa',
        search:'szukaj',
        alltypes:'wszystkie typy',
        withdrawalRecord:'Zapisy wypłat',
        rechargeRecord:'Rekord doładowania',
        withDrawal:'Wypłacić gotówkę',
        UAddress:'Adres U',
        rebateForOrderGrabbing:'Zamów rabat',
        orderGrab:'weź zamówienie',
        recharge:'uzupełniać',
        tryToLoad:'Ładowanie trudne',
        noMore:'więcej nie',
        cancel:'Anulować',
        ok:'Jasne',
        pleaseSelectATime:'Proszę wybrać godzinę'
    },
    teamPage:{
        teamReport:'Raporty zespołu',
        startDate:'data rozpoczęcia',
        endDate:'data końcowa',
        search:'szukaj',
        teamBalance:'Bilans drużyny($)',
        teamFlow:'Przepływ zespołu($)',
        totalTeamRecharge:'Całkowite odnowienie zespołu($)',
        theTotalWithdrawalOfTheTeam:'Całkowite wycofanie zespołu($)',
        teamOrderCommission:'Prowizja od zamówienia zespołowego($)',
        myCommission:'moja prowizja($)',
        directPushNumber:'Liczba bezpośrednich poleceń',
        teamSize:'Rozmiar zespołu',
        todaysNewNumberOfPeople: 'Nowe osoby dodane dzisiaj',
        todaysActiveNumber:'Liczba aktywnych osób dzisiaj',
        level1:'Poziom 1',
        level2:'Poziom 2',
        level3:'Poziom trzeci',
        tryToLoad:'Ładowanie trudne',
        noMore:'więcej nie',
        mobilePhone:'Numer telefonu',
        Number:'Numer promocyjny',
        withdrawals:'Wypłacić gotówkę',
        recharge:'uzupełniać',
        time:'Czas rejestracji'
    },
    culturePage:{
        title:'Kwalifikacje firmy',
        text:'AppLovin wyznaje koncepcję „zorientowania na klienta” i wiarę w „niskie ceny każdego dnia, autentyczne produkty”, sprzedając dziesiątki milionów produktów, takich jak książki, komputery, cyfrowy sprzęt gospodarstwa domowego, domy towarowe dla matek i niemowląt, odzież i torby itp. AppLovin China świadczy profesjonalne usługi: oryginalne licencjonowane towary w niskich cenach każdego dnia, a faktury drukowane maszynowo są objęte gwarancją na terenie całego kraju. Płatność przy odbiorze, zwroty i wymiany w ciągu 30 dni. AppLovin� zapewnia konsumentom na całym świecie wygodne i szybkie zakupy online'
    },
    // 登录页
    loginPage:{
        mobileNumber:'Numer telefonu',
        mobileNumberPlaceholder:'Proszę podać numer telefonu komórkowego',
        mobileNumberMessage:'Proszę wprowadzić standardowy numer telefonu komórkowego',
        password:'hasło',
        passwordPlaceholder:'Proszę wprowadzić hasło',
        passwordMessage:'Wprowadzone hasło jest nieprawidłowe. Spróbuj ponownie.',
        forgotPassword:'zapomnij hasła',
        goToRegister:'Przejdź do rejestracji',
        rememberPassword:'Zapamiętaj hasło',
        login:'Zaloguj się',
        noAccountYet:'Jeszcze nie ma konta?',
        loginSuccessful:'Logowanie powiodło się！',
        loginFailed:'Logowanie nie powiodło się！'
      },
    //   注册页
      registerPage:{
        register:'rejestr',
        mobileNumber:'Numer telefonu',
        mobileNumberPlaceholder :'Proszę podać numer telefonu komórkowego',
        mobileNumberMessage:'Proszę wprowadzić standardowy numer telefonu komórkowego',
        loginPassword:'Hasło logowania',
        loginPasswordPlaceholder:'Proszę podać hasło do logowania',
        loginPasswordMessage:'Ustawione hasło logowania nie może być puste',
        invitationCode:'Kod zaproszenia',
        invitationCodePlaceholder:'Proszę wpisać kod zaproszenia',
        invitationCodeMessage:'Wprowadzony kod zaproszenia jest nieprawidłowy',
        fundingPassword:'Hasło funduszu',
        fundingPasswordPlaceholder:'Proszę podać hasło funduszu',
        fundingPasswordMessage:'Ustawione hasło funduszu nie może być puste',
        iKnowAndAgree:'Zrozumiałem i zgodziłem się',
        accountOpeningAgreement:'"Umowa o otwarcie konta"',
        theTreaties:'traktaty',
        signUpNow:'Zarejestruj się teraz',
        pleaseAgree:'Najpierw zaakceptuj umowę rejestracyjną',
        existingAccount:'Masz już konto',
        loginNow:'"Zaloguj się teraz"',
        registrationFailed:'Rejestracja nie powiodła się',
        registrationSuccessful:'Rejestracja przebiegła pomyślnie',
        iKnow:'Ja wiem',
        accountOpen:'Umowa o otwarcie konta'
      },
      // my页面
      myPage:{
        logout:'Wyloguj się',
        areYouSureYouWantToLogOut:'Czy na pewno się wylogujesz?？',
        confirnText:'Jasne',
        cancleText:'Anulować',
        amazonReputation:'Punkty reputacji:',
        accountBalance:'Saldo konta',
        recharge:'uzupełniać',
        withdrawal:'Wypłacić gotówkę',
        deliveryAddress:'Adres wysyłki',
        iWantToPromote:'Chcę promować',
        secondPurchaseRecord:'Drugi zapis zakupu',
        accountDetails:'Szczegóły konta',
        teamReport:'Raporty zespołu',
        companyQualification:'Kwalifikacje firmy',
		platformRules: 'Regulamin platformy'
      },
      // 充值页面
      rechargePage:{
        recharge:'uzupełniać',
        currentBalance:'Aktualne saldo($)',
        rechargeApplication:'Aplikacja doładowania w toku($)',
        accumulatedRecharged:'Całkowicie naładowany($)',
        rechargeAmount:'Kwota doładowania',
        pleaseEnterTheRechargeAmount:'Proszę wpisać kwotę doładowania',
        rechargeNow:'Naładuj teraz'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'Nie udało się uzyskać listy zamówień'
      },
      // 提现页面
      withDrawalPage:{
       withDrawal:'Wypłacić gotówkę',
       currentBalance:'Aktualna kwota($)',
       withdrawalApplicationInProgress:'Wniosek o wypłatę w toku($)',
       cumulativelyWithdrawn:'Skumulowane wypłaty gotówki($)',
       withdrawalAmount:'Wypłać kwotę',
       pleaseEnterTheWithdrawalAmount:'Proszę wpisać kwotę wypłaty',
       withdrawAll:'Wycofaj wszystko',
       uAddress:'Adres U',
       pleaseEnterUAddress:'Proszę podać adres U',
       transactionPassword:'Hasło transakcji',
       pleaseEnterTheTransactionPassword:'Proszę podać hasło transakcji',
       withdrawNow:'Wycofaj się natychmiast',
       allFieldsCannotBeEmpty:'Wszystkie pola nie mogą być puste',
       able:'Ilość dostępnej gotówki',
	   networkType:'Typ sieci',
	   minPrice: 'Minimalna kwota wypłaty 100 $',
	   minXinyufen: 'Jeśli nie masz wystarczającej zdolności kredytowej i nie możesz wypłacić pieniędzy, skontaktuj się z obsługą klienta!'
   },
      // 主页页面
    indexPage:{
      indexList:'Nie udało się uzyskać danych strony głównej',
      amazonLetMiaogouOpenYourWayToWealth:'AppLovin pozwala miagou otworzyć Ci drogę do bogactwa',
      myAssets:'moje aktywa($)',
      recharge:'uzupełniać',
      withdrawal:'Wypłacić gotówkę',
      yesterdaysIncome:'Wczorajsze zarobki($)',
      cumulativeIncome:'Skumulowany dochód($)',
      todaysIncome:'Dzisiejsze zarobki($)',
      freezeFunds:'zamrozić fundusze($)',
      regularFinancialManagement:'Regularne zarządzanie finansami',
      quickRecharge:'Szybkie ładowanie',
      quickWithdrawal:'Szybka wypłata gotówki',
      inviteFriends:'Zaproś znajomych',
      taskHall:'sala misyjna',
      unlocked:'Odblokowany',
	  service:'obsługa klienta',
	  leaderboard:'Zapisy wypłat'
    },
    addressPage:{
      address:'adres',
      realName:'prawdziwe imię',
      pleaseEnterYourRealName:'Proszę wpisać swoje prawdziwe imię i nazwisko',
      mobileNumber:'Numer telefonu',
      pleaseEnterThePhoneNumber:'Proszę podać numer telefonu komórkowego',
      region:'obszar',
      pleaseEnterTheRegion:'Proszę podać region',
      detailedAddress:'Szczegółowy adres',
      pleaseEnterDetailedAddress:'Proszę podać dokładny adres',
      submit:'składać'
    },
    // order页面
    orderPage:{
      grabAnOrder:'Zbieranie zamówień bez przerwy',
      hiShop:'Cześć, Go',
      winTheFutureWithYou:'Wygraj przyszłość ze sobą',
      orderText:'Założona w 1994 roku firma AppLovin współpracuje z ponad milionem firm zajmujących się handlem elektronicznym i realizuje od 150 000 do 30 milionów zamówień dziennie. Ponieważ zarządzanie funduszem powierzono firmie zarządzającej aktywami, można uniknąć żądań zwrotu środków przez użytkowników i ucieczki funduszy przez firmę zajmującą się handlem elektronicznym. Klienci Smart Cloud i adresy IP użytkowników są wykorzystywane zgodnie z ustalonymi zasadami. Automatyczna konkurencja w składaniu zamówień zapobiegnie blokowaniu, obniżaniu jakości lub blokowaniu sprzedawców z powodu błędów użytkowników przejmujących zamówienia. Jako pośrednik będziemy nadal ciężko pracować, aby poprawić zaufanie i efektywność sprzedawców i użytkowników podczas rywalizacji o zamówienia!',
      bronzeMember:'Członek Brązowy',
      commission:'zamawiać',
      Matching:'Zamówienie jest dopasowywane do Ciebie. Proszę czekać.',
      dispatch:'polecenie wysyłki',
      grabAnOrder:'weź zamówienie',
      balance:'Balansować',
      recharge:'uzupełniać',
      withdrawal:'Wypłacić gotówkę',
      matchingRules:'Dopasowane zasady',
      commissionEarnedToday:'Prowizja zarobiona dzisiaj',
      Today:'Liczba zamówień przyjętych dzisiaj',
      oddNumber:'Pozostałe dzisiejsze liczby nieparzyste',
      yesterdays:'Wczorajsza komisja',
      User:'użytkownik',
      getCommission:'otrzymać prowizję'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:'Chcę promować',
      inviteNewUsers:'Zaproś nowych użytkowników',
      winTheFutureWithYou:'Wygraj przyszłość ze sobą',
      screenshot:'Zrób zrzut ekranu i zapisz kod QR',
      friend:'Identyfikacja i rejestracja znajomych',
      complete:'Dokończ rejestrację i zbieraj zamówienia',
      distribute:'Czerwone koperty gotówkowe dystrybuowane w ciągu 24 godzin',
      invitationCode:'Kod zaproszenia:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'Drugi zapis zakupu',
      this:'Dane te są dostarczane przez głównych urzędników spółdzielni',
      Remaining:'pozostałe dostępne aktywa($)',
      allOrders:'Wszystkie zamówienia',
      toBeProcessed:'Aż do',
      completed:'Zakończony',
      freezing:'Zamrażanie',
      totalAmountOfTheOrder:'Całkowite saldo zamówienia',
      commission:'zamawiać',
      Estimated:'Szacunkowy rabat prowizji',
      submitOrder:'Prześlij zamówienie',
      secondpurchasetime:'Drugi moment zakupu:',
      secondPurchaseNumber:'Drugi numer zakupu:',
      NotSubmitted:'Jeszcze nie przesłane',
      submitNow:'Prześlij teraz'
    },
	vipPage:{
		'agent_mode': 'tryb proxy',
		'current_level': 'obecny poziom',
		'vip1_text1': 'Otrzymaj zestaw 40 zadań związanych z danymi aplikacji',
		'vip1_text2': '0.50% zysku na aplikację',
		'vip1_text3': 'Aktywuj za 100.00 USDT',
		
		'vip2_text1': 'Otrzymaj zestaw 50 zadań związanych z danymi aplikacji',
		'vip2_text2': '0.60% zysku na aplikację',
		'vip2_text3': 'Aktywuj za 2,000.00 USDT',
		
		'vip3_text1': 'Otrzymaj zestaw 55 zadań związanych z danymi aplikacji',
		'vip3_text2': '0.80% zysku na aplikację',
		'vip3_text3': 'Aktywuj za 5,000.00 USDT',
		
		'vip4_text1': 'Otrzymaj zestaw 60 zadań związanych z danymi aplikacji',
		'vip4_text2': '1.00% zysku na aplikację',
		'vip4_text3': 'Aktywuj za 10,000.00 USDT',
	}
}