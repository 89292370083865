import axios from '../request/index,js'

 function postApi(url,params) {
    return axios({
        method:'post',
        url:url,
        data:{
            token:localStorage.getItem('token') || 'en-US',
            la:localStorage.getItem('lang') || 'en-US',
            ...params
        }
    })
}

function getApi(url,params) {
    return axios({
       url:url,
       method:'get',
       params:{
        token:localStorage.getItem('token') || 'en-US',
        la:localStorage.getItem('lang') || 'en-US',
        ...params
       }
    })
}
export {
    postApi,
    getApi
}