//意大利
export default {
    accountDetailsPage:{
        accountDetails:'Dettagli del conto',
        startDate:'Data di inizio',
        endDate:'Data di fine',
        search:'Ricerca',
        alltypes:'Tutti i tipi',
        withdrawalRecord:'Cronologia dei prelievi',
        rechargeRecord:'Cronologia delle ricariche',
        withDrawal:'Pagamenti',
        UAddress:'Indirizzo U',
        rebateForOrderGrabbing:'Sconti per l\'acquisizione di ordini',
        orderGrab:'Prendi gli ordini',
        recharge:'ricaricare',
        tryToLoad:'Tentativo di caricamento',
        noMore:'Non più',
        cancel:'Annulla',
        ok:'Sei sicuro',
        pleaseSelectATime:'Si prega di selezionare un orario'
    },
    teamPage:{
        teamReport:'Rapporti di squadra',
        startDate:'Data di inizio',
        endDate:'Data di fine',
        search:'Ricerca',
        teamBalance:'Bilanciamento della squadra ($)',
        teamFlow:'Fatturato della squadra ($)',
        totalTeamRecharge:'Totale Ricarica Squadra ($)',
        theTotalWithdrawalOfTheTeam:'Il ritiro totale della squadra($)',
        teamOrderCommission:'Commissione sugli ordini di squadra($)',
        myCommission:'Le mie commissioni($)',
        directPushNumber:'Spinta diretta per il numero di persone',
        teamSize:'Numero di persone nel gruppo',
        todaysNewNumberOfPeople: 'Il numero di nuove persone aggiunte oggi',
        todaysActiveNumber:'I numeri attivi di oggi',
        level1:'Livello',
        level2:'Livello 2',
        level3:'Livello 3',
        tryToLoad:'Tentativo di caricamento',
        noMore:'Non più',
        mobilePhone:'Numero di cellulare',
        Number:'Numero di promotori',
        withdrawals:'Pagamenti',
        recharge:'ricaricare',
        time:'Orario di registrazione'
    },
    culturePage:{
        title:'Qualifiche aziendali',
        text:'AppLovin aderisce al concetto di "incentrato sul cliente" e aderisce alla convinzione di "prezzi bassi ogni giorno, prodotti con licenza originale" e vende decine di milioni di prodotti come libri, computer, elettrodomestici digitali, grandi magazzini materni e infantili, abbigliamento e borse. AppLovin China fornisce servizi professionali: i prodotti con licenza originale vengono venduti ogni giorno a prezzi bassi e le fatture stampate a macchina sono garantite a livello nazionale. Contrassegno, reso entro 30 giorni. AppLovin offre un\'esperienza di acquisto online comoda e veloce per i consumatori di tutto il mondo'
    },
    // 登录页
    loginPage:{
        mobileNumber:'Numero di cellulare',
        mobileNumberPlaceholder:'Inserisci il tuo numero di cellulare',
        mobileNumberMessage:'Inserisci il numero di cellulare richiesto',
        password:'parola d’ordine',
        passwordPlaceholder:'Inserisci la tua password',
        passwordMessage:'Hai inserito la password sbagliata, riprova',
        forgotPassword:'Password dimenticata',
        goToRegister:'Vai a Registrati',
        rememberPassword:'Ricorda la tua password',
        login:'accesso',
        noAccountYet:'Non hai ancora un account?',
        loginSuccessful:'Accesso riuscito！',
        loginFailed:'Accesso non riuscito！'
      },
    //   注册页
      registerPage:{
        register:'iscriversi',
        mobileNumber:'Numero di cellulare',
        mobileNumberPlaceholder :'Inserisci il tuo numero di cellulare',
        mobileNumberMessage:'Inserisci il numero di cellulare richiesto',
        loginPassword:'Password di accesso',
        loginPasswordPlaceholder:'Inserisci la tua password di accesso',
        loginPasswordMessage:'La password di accesso impostata non può essere vuota',
        invitationCode:'Codice invito',
        invitationCodePlaceholder:'Inserisci il codice invito',
        invitationCodeMessage:'Hai inserito il codice di invito sbagliato',
        fundingPassword:'Password di finanziamento',
        fundingPasswordPlaceholder:'Inserisci il tuo PIN',
        fundingPasswordMessage:'La password del fondo impostata non può essere vuota',
        iKnowAndAgree:'Lo so e sono d\'accordo',
        accountOpeningAgreement:'"Contratto di apertura del conto"',
        theTreaties:'Trattati',
        signUpNow:'Iscriviti ora',
        pleaseAgree:'Si prega di accettare prima l\'accordo di registrazione',
        existingAccount:'Hai già un account',
        loginNow:'"Accedi ora"',
        registrationFailed:'Registrazione non riuscita',
        registrationSuccessful:'La registrazione è andata a buon fine',
        iKnow:'Lo so',
        accountOpen:'Contratto di apertura del conto'
      },
      // my页面
      myPage:{
        logout:'Disconnettersi',
        areYouSureYouWantToLogOut:'Se sei sicuro di disconnetterti？',
        confirnText:'Sei sicuro',
        cancleText:'Annulla',
        amazonReputation:'Punteggio di reputazione:',
        accountBalance:'Saldo del conto',
        recharge:'ricaricare',
        withdrawal:'Pagamenti',
        deliveryAddress:'Indirizzo di consegna',
        iWantToPromote:'Ho intenzione di promuovere',
        secondPurchaseRecord:'Registrazioni dei secondi acquisti',
        accountDetails:'Dettagli del conto',
        teamReport:'Rapporti di squadra',
        companyQualification:'Qualifiche aziendali',
		platformRules: 'Regole della piattaforma'
      },
      // 充值页面
      rechargePage:{
        recharge:'ricaricare',
        currentBalance:'Saldo corrente($)',
        rechargeApplication:'La richiesta di ricarica è in corso($)',
        accumulatedRecharged:'Ricarica cumulativa($)',
        rechargeAmount:'Importo della ricarica',
        pleaseEnterTheRechargeAmount:'Inserisci l\'importo della ricarica',
        rechargeNow:'Ricarica ora'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'Impossibile ottenere l\'elenco di acquisizione degli ordini'
      },
      // 提现页面
      withDrawalPage:{
       withDrawal:'Pagamenti',
       currentBalance:'L\'importo attuale($)',
       withdrawalApplicationInProgress:'Le richieste di prelievo sono in corso($)',
       cumulativelyWithdrawn:'Prelievi cumulativi($)',
       withdrawalAmount:'L\'importo da prelevare',
       pleaseEnterTheWithdrawalAmount:'Inserisci l\'importo del prelievo',
       withdrawAll:'Prelievo completo',
       uAddress:'Indirizzo U',
       pleaseEnterUAddress:'Inserisci il tuo indirizzo',
       transactionPassword:'Password della transazione',
       pleaseEnterTheTransactionPassword:'Inserisci la tua password di trading',
       withdrawNow:'Ritira immediatamente',
       allFieldsCannotBeEmpty:'Tutti i campi non possono essere vuoti',
       able:'L\'importo disponibile per il prelievo',
	   networkType:'Il tipo di rete',
	   minPrice: 'L\'importo minimo di prelievo è di $ 100',
	   minXinyufen: 'Se il credito è insufficiente e non puoi prelevare denaro, contatta il servizio clienti！'
   },
      // 主页页面
    indexPage:{
      indexList:'Impossibile ottenere i dati Home',
      amazonLetMiaogouOpenYourWayToWealth:'AppLovin ti apre la strada verso la ricchezza con SecondsBuy',
      myAssets:'I miei beni($)',
      recharge:'ricaricare',
      withdrawal:'Pagamenti',
      yesterdaysIncome:'Guadagni di ieri($)',
      cumulativeIncome:'Guadagni cumulativi($)',
      todaysIncome:'Guadagni di oggi($)',
      freezeFunds:'Congelamento dei capitali($)',
      regularFinancialManagement:'Gestisci regolarmente il tuo denaro',
      quickRecharge:'Ricariche rapide',
      quickWithdrawal:'Pagamenti rapidi',
      inviteFriends:'Invita gli amici',
      taskHall:'Lobby delle missioni',
      unlocked:'Sbloccato',
	  service:'Cliente',
	  leaderboard:'Cronologia dei prelievi'
    },
    addressPage:{
      address:'indirizzo',
      realName:'Vero nome',
      pleaseEnterYourRealName:'Inserisci il tuo vero nome',
      mobileNumber:'Numero di cellulare',
      pleaseEnterThePhoneNumber:'Inserisci il tuo numero di cellulare',
      region:'regione',
      pleaseEnterTheRegion:'Inserisci una regione',
      detailedAddress:'Indirizzo completo',
      pleaseEnterDetailedAddress:'Inserisci i dettagli del tuo indirizzo',
      submit:'Invia'
    },
    // order页面
    orderPage:{
      grabAnOrder:'La corsa all\'acquisto degli ordini è continua',
      hiShop:'Ciao negozio',
      winTheFutureWithYou:'Un futuro vantaggioso per tutti con te',
      orderText:'Fondata nel 1994, AppLovin collabora con oltre 1 milione di aziende di e-commerce ed elabora tra i 150.000 e i 30 milioni di ordini al giorno. Poiché la gestione del fondo è affidata alla società di gestione del risparmio, può evitare le richieste di rimborso degli utenti e la fuga di fondi dalla società di e-commerce, e il client cloud intelligente e l\IP dell\'utente vengono utilizzati in conformità con le regole stabilite. Il concorso automatico degli ordini impedirà ai commercianti di essere bannati, retrocessi e bannati a causa di errori degli order grabber. In qualità di intermediari, continueremo a lavorare sodo per migliorare la fiducia e l\'efficienza dei commercianti e degli utenti quando competono per gli ordini!',
      bronzeMember:'Membro di bronzo',
      commission:'commissione',
      Matching:'Ordini corrispondenti per te, attendi',
      dispatch:'Spedizione',
      grabAnOrder:'Prendi gli ordini',
      balance:'bilancia',
      recharge:'ricaricare',
      withdrawal:'Pagamenti',
      matchingRules:'Regole di corrispondenza',
      commissionEarnedToday:'Le commissioni sono state guadagnate oggi',
      Today:'Il numero di ordini è stato raggiunto oggi',
      oddNumber:'Numeri singolari rimasti oggi',
      yesterdays:'La commissione di ieri',
      User:'utente',
      getCommission:'Ricevi una commissione'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:'Ho intenzione di promuovere',
      inviteNewUsers:'Invita nuovi utenti',
      winTheFutureWithYou:'Un futuro vantaggioso per tutti con te',
      screenshot:'Fai uno screenshot per salvare il codice QR',
      friend:'Gli amici vengono identificati e registrati',
      complete:'Completa la registrazione e prendi l\'ordine',
      distribute:'Le buste rosse in contanti saranno distribuite entro 24 ore',
      invitationCode:'Codice invito:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'Registrazioni dei secondi acquisti',
      this:'Questi dati sono forniti dai principali partner',
      Remaining:'Attività disponibili rimanenti($)',
      allOrders:'Tutto singolo',
      toBeProcessed:'In sospeso',
      completed:'Fatto',
      freezing:'Congelato',
      totalAmountOfTheOrder:'Il saldo totale dell\'ordine',
      commission:'commissione',
      Estimated:'Sconti stimati',
      submitOrder:'Invia il tuo ordine',
      secondpurchasetime:'Tempo di acquisto in secondi:',
      secondPurchaseNumber:'Numero d\'acquisto:',
      NotSubmitted:'Non inviato in questo momento',
      submitNow:'Invia ora'
    },
	vipPage:{
		'agent_mode': 'Modalità proxy',
		'current_level': 'Livello attuale',
		'vip1_text1': 'Ricevi un set di 40 attività relative ai dati dell\'app',
		'vip1_text2': '0.50% di profitto per applicazione',
		'vip1_text3': 'Attiva con 100.00 USDT',
		
		'vip2_text1': 'Ricevi un set di 50 attività relative ai dati dell\'app',
		'vip2_text2': '0.60% di profitto per applicazione',
		'vip2_text3': 'Attiva con 2,000.00 USDT',
		
		'vip3_text1': 'Ricevi un set di 55 attività relative ai dati dell\'app',
		'vip3_text2': '0.80% di profitto per applicazione',
		'vip3_text3': 'Attiva con 5,000.00 USDT',
		
		'vip4_text1': 'Ricevi un set di 60 attività relative ai dati dell\'app',
		'vip4_text2': '1.00% di profitto per applicazione',
		'vip4_text3': 'Attiva con 10,000.00 USDT',
	}
}