//罗马尼亚
export default {
    accountDetailsPage:{
        accountDetails:'Detaliile contului',
        startDate:'Data de începere',
        endDate:'Data de încheiere',
        search:'Căutare',
        alltypes:'Toate tipurile',
        withdrawalRecord:'Istoricul retragerilor',
        rechargeRecord:'Istoricul reîncărcărilor',
        withDrawal:'Plăţile',
        UAddress:'Adresa U',
        rebateForOrderGrabbing:'Reduceri pentru preluarea comenzilor',
        orderGrab:'Comenzi',
        recharge:'Reîncărcaţi',
        tryToLoad:'Încercarea de încărcare',
        noMore:'Ajunge',
        cancel:'Anula',
        ok:'Eşti sigur',
        pleaseSelectATime:'Vă rugăm să selectați o oră'
    },
    teamPage:{
        teamReport:'Rapoartele echipei',
        startDate:'Data de începere',
        endDate:'Data de încheiere',
        search:'Căutare',
        teamBalance:'Echilibrul echipei($)',
        teamFlow:'Fluxul de echipă($)',
        totalTeamRecharge:'Completarea totală a echipei($)',
        theTotalWithdrawalOfTheTeam:'Retragerea totală a echipei($)',
        teamOrderCommission:'Comision pentru comenzile de echipă($)',
        myCommission:'Comisioanele mele($)',
        directPushNumber:'Împingere directă pentru numărul de persoane',
        teamSize:'Numărul de persoane din grup',
        todaysNewNumberOfPeople: 'Numărul de persoane noi adăugate astăzi',
        todaysActiveNumber:'Cifrele active de astăzi',
        level1:'Nivel',
        level2:'Nivelul 2',
        level3:'Nivelul 3',
        tryToLoad:'Încercarea de încărcare',
        noMore:'Ajunge',
        mobilePhone:'Număr de telefon mobil',
        Number:'Numărul de promotori',
        withdrawals:'Plăţile',
        recharge:'Reîncărcaţi',
        time:'Momentul înregistrării'
    },
    culturePage:{
        title:'Calificările companiei',
        text:'AppLovin aderă la conceptul de "centrat pe client" și aderă la credința "prețuri mici în fiecare zi, produse licențiate autentice" și vinde zeci de milioane de produse, cum ar fi cărți, computere, electrocasnice digitale, magazine universale pentru mame și copii, îmbrăcăminte și genți. AppLovin China oferă servicii profesionale: produsele originale licențiate sunt vândute la prețuri mici în fiecare zi, iar facturile tipărite automat sunt garantate la nivel național. Ramburs, returnare în termen de 30 de zile. AppLovin oferă o experiență de cumpărături online convenabilă și rapidă pentru consumatorii din întreaga lume'
    },
    // 登录页
    loginPage:{
        mobileNumber:'Număr de telefon mobil',
        mobileNumberPlaceholder:'Vă rugăm să introduceți numărul de telefon mobil',
        mobileNumberMessage:'Vă rugăm să introduceți numărul de telefon mobil dorit',
        password:'parolă',
        passwordPlaceholder:'Vă rugăm să introduceți parola',
        passwordMessage:'Ați introdus parola greșită, vă rugăm să încercați din nou',
        forgotPassword:'Am uitat parola',
        goToRegister:'Mergi la Înregistrare',
        rememberPassword:'Amintiți-vă parola',
        login:'Login',
        noAccountYet:'Nu ai încă un cont?',
        loginSuccessful:'Autentificare reușită！',
        loginFailed:'Conectarea a eșuat！'
      },
    //   注册页
      registerPage:{
        register:'Înscrie',
        mobileNumber:'Număr de telefon mobil',
        mobileNumberPlaceholder :'Vă rugăm să introduceți numărul de telefon mobil',
        mobileNumberMessage:'Vă rugăm să introduceți numărul de telefon mobil dorit',
        loginPassword:'Parolă de conectare',
        loginPasswordPlaceholder:'Vă rugăm să introduceți parola de conectare',
        loginPasswordMessage:'Parola de conectare pe care ați setat-o nu poate fi goală',
        invitationCode:'Cod de invitație',
        invitationCodePlaceholder:'Vă rugăm să introduceți codul de invitație',
        invitationCodeMessage:'Ați introdus codul de invitație greșit',
        fundingPassword:'Parole de finanțare',
        fundingPasswordPlaceholder:'Vă rugăm să introduceți codul PIN',
        fundingPasswordMessage:'Parola fondului pe care ați setat-o nu poate fi goală',
        iKnowAndAgree:'Știu și sunt de acord',
        accountOpeningAgreement:'"Acord de deschidere a contului"',
        theTreaties:'Tratatele',
        signUpNow:'Înscrieți-vă acum',
        pleaseAgree:'Vă rugăm să acceptați mai întâi acordul de înregistrare',
        existingAccount:'Ai deja un cont',
        loginNow:'"Conectează-te acum"',
        registrationFailed:'Înregistrarea a eșuat',
        registrationSuccessful:'Înregistrarea este reușită',
        iKnow:'Ştiu',
        accountOpen:'Acord de deschidere a contului'
      },
      // my页面
      myPage:{
        logout:'Deconectați-vă',
        areYouSureYouWantToLogOut:'Dacă sunteți sigur că vă deconectați？',
        confirnText:'Eşti sigur',
        cancleText:'Anula',
        amazonReputation:'Scorul reputației:',
        accountBalance:'Soldul contului',
        recharge:'Reîncărcaţi',
        withdrawal:'Plăţile',
        deliveryAddress:'Adresa de livrare',
        iWantToPromote:'Voi promova',
        secondPurchaseRecord:'Înregistrări ale celei de-a doua achiziții',
        accountDetails:'Detaliile contului',
        teamReport:'Rapoartele echipei',
        companyQualification:'Calificările companiei',
		platformRules: 'Regulile platformei'
      },
      // 充值页面
      rechargePage:{
        recharge:'Reîncărcaţi',
        currentBalance:'Soldul curent($)',
        rechargeApplication:'Cererea de reîncărcare este în curs de desfășurare($)',
        accumulatedRecharged:'Reîncărcare cumulată($)',
        rechargeAmount:'Suma de reîncărcare',
        pleaseEnterTheRechargeAmount:'Vă rugăm să introduceți suma de reîncărcare',
        rechargeNow:'Reîncărcați acum'
      },
      // 抢单列表页面
      grabListPage:{
        failedToGetTheOrderGrabList:'Nu s-a reușit să obțină lista de preluare a comenzilor'
      },
      // 提现页面
      withDrawalPage:{
       withDrawal:'Plăţile',
       currentBalance:'Suma curentă($)',
       withdrawalApplicationInProgress:'Cererile de retragere sunt în curs de desfășurare($)',
       cumulativelyWithdrawn:'Retrageri cumulate($)',
       withdrawalAmount:'Suma care trebuie retrasă',
       pleaseEnterTheWithdrawalAmount:'Vă rugăm să introduceți suma de retragere',
       withdrawAll:'Retrageți integral',
       uAddress:'Adresa U',
       pleaseEnterUAddress:'Vă rugăm să introduceți adresa dvs.',
       transactionPassword:'Parola tranzacției',
       pleaseEnterTheTransactionPassword:'Vă rugăm să introduceți parola de tranzacționare',
       withdrawNow:'Retrage imediat',
       allFieldsCannotBeEmpty:'Toate câmpurile nu pot fi goale',
       able:'Suma disponibilă pentru retragere',
	   networkType:'Tipul de rețea',
	   minPrice: 'Suma minimă de retragere este de 100 USD',
	   minXinyufen: 'Dacă nu aveți un scor de credit insuficient și nu puteți retrage bani, vă rugăm să contactați serviciul clienți!'
   },
      // 主页页面
    indexPage:{
      indexList:'Nu s-au putut obține datele de acasă',
      amazonLetMiaogouOpenYourWayToWealth:'AppLovin îți deschide calea către bogăție cu SecondsBuy',
      myAssets:'Activele mele($)',
      recharge:'Reîncărcaţi',
      withdrawal:'Plăţile',
      yesterdaysIncome:'Câștigurile de ieri($)',
      cumulativeIncome:'Câștiguri cumulate($)',
      todaysIncome:'Câștigurile de astăzi($)',
      freezeFunds:'Înghețarea fondurilor($)',
      regularFinancialManagement:'Gestionează-ți banii în mod regulat',
      quickRecharge:'Reîncărcări rapide',
      quickWithdrawal:'Plăți rapide',
      inviteFriends:'Invită prieteni',
      taskHall:'Holurile misiunilor',
      unlocked:'Descuiat',
	  service:'Client',
	  leaderboard:'Istoricul retragerilor'
    },
    addressPage:{
      address:'adresă',
      realName:'Nume real',
      pleaseEnterYourRealName:'Te rugăm să introduci numele tău real',
      mobileNumber:'Număr de telefon mobil',
      pleaseEnterThePhoneNumber:'Vă rugăm să introduceți numărul de telefon mobil',
      region:'regiune',
      pleaseEnterTheRegion:'Vă rugăm să introduceți o regiune',
      detailedAddress:'Adresa completă',
      pleaseEnterDetailedAddress:'Vă rugăm să introduceți detaliile adresei dvs.',
      submit:'Prezinte'
    },
    // order页面
    orderPage:{
      grabAnOrder:'Graba de a cumpăra comenzi este non-stop',
      hiShop:'Bună magazin',
      winTheFutureWithYou:'Viitorul câștig-câștig cu tine',
      orderText:'Fondată în 1994&nbsp;, AppLovin lucrează cu peste 1 milion de companii de comerț electronic și procesează între 150.000 și 30 de milioane de comenzi pe zi. Deoarece administrarea fondului este încredințată companiei de administrare a activelor, aceasta poate evita cererile de rambursare ale utilizatorilor și fuga de fonduri de la compania de comerț electronic, iar clientul cloud inteligent și IP-ul utilizatorului sunt utilizate în conformitate cu regulile stabilite. Concursul automat de comenzi va împiedica comercianții să fie interziși, retrogradați și interziși din cauza greșelilor celor care acaparează comenzi. În calitate de intermediar, vom continua să lucrăm din greu pentru a îmbunătăți încrederea și eficiența comercianților și utilizatorilor atunci când concurează pentru comenzi!',
      bronzeMember:'Membru de bronz',
      commission:'comisie',
      Matching:'Comenzi potrivite pentru dvs., vă rugăm să așteptați',
      dispatch:'Expediere',
      grabAnOrder:'Comenzi',
      balance:'echilibru',
      recharge:'Reîncărcaţi',
      withdrawal:'Plăţile',
      matchingRules:'Reguli de corelare',
      commissionEarnedToday:'Comisioanele au fost câștigate astăzi',
      Today:'Numărul de comenzi a fost luat astăzi',
      oddNumber:'Numere singulare rămase astăzi',
      yesterdays:'Comisia de ieri',
      User:'utilizator',
      getCommission:'Primiți comision'
    },
    // Popularize页面
    popularizePage:{
      iWantToPromote:'Voi promova',
      inviteNewUsers:'Invitați utilizatori noi',
      winTheFutureWithYou:'Viitorul câștig-câștig cu tine',
      screenshot:'Faceți o captură de ecran pentru a salva codul QR',
      friend:'Prietenii sunt identificați și înregistrați',
      complete:'Finalizați înregistrarea și luați comanda',
      distribute:'Plicurile cu numerar roșu vor fi distribuite în termen de 24 de ore',
      invitationCode:'Cod de invitație:'
    },
    // list页面
    listPage:{
      secondPurchaseRecord:'Înregistrări ale celei de-a doua achiziții',
      this:'Aceste date sunt furnizate de principalii parteneri',
      Remaining:'Activele disponibile rămase($)',
      allOrders:'Toate singure',
      toBeProcessed:'Până',
      completed:'Făcut',
      freezing:'Înghețat',
      totalAmountOfTheOrder:'Soldul total al ordinului',
      commission:'comisie',
      Estimated:'Reduceri estimate',
      submitOrder:'Trimiteți comanda',
      secondpurchasetime:'Timp de achiziție în câteva secunde:',
      secondPurchaseNumber:'Numărul de achiziție:',
      NotSubmitted:'Nu a fost trimis în acest moment',
      submitNow:'Trimiteți acum'
    },
	vipPage:{
		'agent_mode': 'Mod proxy',
		'current_level': 'Nivelul curent',
		'vip1_text1': 'Primiți un set de 40 de activități de date ale aplicației',
		'vip1_text2': '0.50% profit per aplicație',
		'vip1_text3': 'Activați cu 100.00 USDT',
		
		'vip2_text1': 'Primiți un set de 50 de activități de date ale aplicației',
		'vip2_text2': '0.60% profit per aplicație',
		'vip2_text3': 'Activați cu 2,000.00 USDT',
		
		'vip3_text1': 'Primiți un set de 55 de activități de date ale aplicației',
		'vip3_text2': '0.80% profit per aplicație',
		'vip3_text3': 'Activați cu 5,000.00 USDT',
		
		'vip4_text1': 'Primiți un set de 60 de activități de date ale aplicației',
		'vip4_text2': '1.00% profit per aplicație',
		'vip4_text3': 'Activați cu 10,000.00 USDT',
	}
}